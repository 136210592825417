<template>
  <div class="app-union-manage">
    <b-card>
      <b-form
        v-if="groupValidationLoaded"
        :class="{ 'invalid-form': isSubmitted }"
        @submit.prevent
      >
        <b-row>
          <b-col
            v-if="dataInfo.validations.nameVisible"
            md="4"
          >
            <input-group
              v-model="dataInfo.firstName"
              :value="dataInfo.firstName"
              :label="'Name'"
              image="/images/members/name.svg"
              :make-required="dataInfo.validations.nameRequired"
            />
          </b-col>
          <b-col
            v-if="dataInfo.validations.surnameVisible"
            md="4"
          >
            <input-group
              v-model="dataInfo.surname"
              :value="dataInfo.surname"
              :label="'Surname'"
              image="/images/members/surname.svg"
              :make-required="dataInfo.validations.surnameRequired"
            />
          </b-col>
          <b-col
            v-if="!isNonMember && dataInfo.validations.relationVisible"
            md="4"
          >
            <input-group
              v-model="dataInfo.fatherOrHusbandName"
              :value="dataInfo.fatherOrHusbandName"
              :label="'Son / Daughter of'"
              image="/images/members/spouse.svg"
            />
          </b-col>
          <b-col
            v-if="!isNonMember && dataInfo.validations.aadharVisible"
            md="4"
          >
            <input-group
              v-model="dataInfo.aadhaarNo"
              :value="dataInfo.aadhaarNo"
              :label="'Aadhaar'"
              image="/images/members/aadhaar.svg"
              :mask="'####-####-####'"
              :make-required="
                dataInfo.validations.aadharOrMobile
                  ? false
                  : dataInfo.validations.aadharRequired
              "
            />
          </b-col>
          <b-col
            v-if="isNonMember && dataInfo.validations.organizationVisible"
            md="4"
          >
            <input-group
              v-model="dataInfo.organization"
              :value="dataInfo.organization"
              :label="'Organization'"
              image="/images/members/organisation.svg"
              :make-required="dataInfo.validations.organizationRequired"
            />
          </b-col>
          <b-col
            v-if="dataInfo.validations.mobileVisible"
            md="4"
          >
            <input-group
              v-model="dataInfo.mobileNo"
              :value="dataInfo.mobileNo"
              :label="'Mobile'"
              image="/images/members/mobile.svg"
              :mask="'#####-#####'"
              :make-required="dataInfo.validations.aadharOrMobile
                ? false
                : dataInfo.validations.mobileRequired"
            />
          </b-col>
          <b-col
            v-if="isNonMember && dataInfo.validations.landlinePhoneVisible"
            md="4"
          >
            <input-group
              v-model="dataInfo.landlinePhone"
              :value="dataInfo.landlinePhone"
              :label="'Other Mobile'"
              image="/images/members/otherPh.svg"
              :mask="'#####-#####'"
              :make-required="dataInfo.validations.landlinePhoneRequired"
            />
          </b-col>
          <b-col
            v-if="!isNonMember && dataInfo.validations.sexVisible"
            md="4"
          >
            <select-group
              v-model="dataInfo.gender"
              :value="dataInfo.gender"
              :label="'Sex'"
              :options="$manMudra.constants.genders"
              option-label="label"
              image="/images/members/sex.svg"
              :make-required="
                dataInfo.validations.sexRequired"
            />
          </b-col>
          <b-col
            v-if="!isNonMember && dataInfo.validations.maritalVisible"
            md="4"
          >
            <select-group
              v-model="dataInfo.maritalStatus"
              :value="dataInfo.maritalStatus"
              :label="'Marital'"
              :options="$manMudra.constants.maritial"
              option-label="label"
              image="/images/members/marital.svg"
              :make-required="
                dataInfo.validations.maritalRequired"
            />
          </b-col>
          <b-col
            v-if="!isNonMember && dataInfo.validations.ageVisible"
            md="4"
          >
            <input-group
              v-model="dataInfo.age"
              :value="dataInfo.age"
              :label="'Age'"
              image="/images/members/age.svg"
              :type="'number'"
              :mask="'##'"
              :make-required="dataInfo.validations.ageRequired"
            />
          </b-col>
          <b-col
            v-if="!isNonMember && dataInfo.validations.dateOfBirthVisible"
            md="4"
          >
            <input-group
              v-model="dataInfo.dateOfBirth"
              :value="dataInfo.dateOfBirth"
              :label="'Date of Birth'"
              image="/images/members/dateOfBirth.svg"
              :type="'date'"
              :make-required="dataInfo.validations.dateOfBirthRequired"
            />
          </b-col>

          <b-col md="12">
            <app-collapse
              accordion
              type="margin"
              class="mt-2"
            >
              <app-collapse-item
                v-if="dataInfo.address && unionSelected && unionSelected.showAddressFields"
                :title="'Address'"
                class="bo-yellow bg-cu-yellow"
              >
                <b-row>
                  <b-col
                    v-if="dataInfo.validations.stateVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.address.stateId"
                      :value="dataInfo.address.stateId"
                      :label="'State'"
                      :options="state"
                      option-value="id"
                      option-label="name"
                      image="/images/members/state.svg"
                      :make-required="dataInfo.validations.stateVisible"
                      @update="getDistricts('yes')"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.districtVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.address.districtId"
                      :value="dataInfo.address.districtId"
                      :label="'District'"
                      :options="district"
                      option-value="id"
                      option-label="name"
                      image="/images/members/district.svg"
                      :make-required="dataInfo.validations.districtRequired"
                      @update=" getSubDistricts('yes');
                                getBlock('yes');"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.tahsilVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.address.subDistrictId"
                      :value="dataInfo.address.subDistrictId"
                      :label="'Tehsil/Taluk'"
                      :options="taluk"
                      option-value="id"
                      option-label="name"
                      image="/images/members/tahsil.svg"
                      :make-required="dataInfo.validations.tahsilRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.blockVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.address.blockId"
                      :value="dataInfo.address.blockId"
                      :label="'Block'"
                      :options="block"
                      option-value="id"
                      option-label="name"
                      image="/images/members/block.svg"
                      :make-required="dataInfo.validations.blockRequired"
                      @update="getPanchayat('yes')"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.panchayatVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.address.panchayatId"
                      :value="dataInfo.address.panchayatId"
                      :label="'Panchayat'"
                      :options="panchayat"
                      option-value="id"
                      option-label="name"
                      image="/images/members/panchayat.svg"
                      :make-required="dataInfo.validations.panchayatRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.townVillageVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.address.cityOrVillage"
                      :value="dataInfo.address.cityOrVillage"
                      :label="'City / Village'"
                      image="/images/members/village.svg"
                      :type="'text'"
                      :make-required="
                        dataInfo.validations.townVillageRequired
                      "
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.pincodeVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.address.pinCode"
                      :value="dataInfo.address.pinCode"
                      :label="'Pin Code'"
                      image="/images/members/pinCode.svg"
                      :type="'number'"
                      :make-required="dataInfo.validations.pincodeRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.houseNoVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.address.houseNo"
                      :value="dataInfo.address.houseNo"
                      :label="'House No. Road'"
                      image="/images/members/houseNo.svg"
                      :type="'text'"
                      :make-required="dataInfo.validations.houseNoRequired"
                    />
                  </b-col>
                  <!-- <b-col
                    v-if="dataInfo.validations.localityVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.address.locality"
                      :value="dataInfo.address.locality"
                      :label="'Locality'"
                      image="/images/members/locality.svg"
                      :type="'text'"
                      :make-required="
                        dataInfo.validations.localityRequired
                      "
                    />
                  </b-col> -->
                  <b-col
                    v-if="dataInfo.validations.postOfficeVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.address.postOffice"
                      :value="dataInfo.address.postOffice"
                      :label="'PO (Post Office)'"
                      image="/images/members/postOffice.svg"
                      :type="'text'"
                      :make-required="
                        dataInfo.validations.postOfficeRequired
                      "
                    />
                  </b-col>
                  <b-col
                    v-if="!isNonMember && dataInfo.validations.emailVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.email"
                      :value="dataInfo.email"
                      :label="'Email'"
                      image="/images/members/email.svg"
                      :type="'text'"
                      :make-required="dataInfo.validations.emailRequired"
                    />
                  </b-col>
                </b-row>
              </app-collapse-item>

              <app-collapse-item
                v-if="!isNonMember && unionSelected && unionSelected.showSocialFields"
                :title="'Socioeconomic'"
                class="bg-cu-amber bo-amber"
              >
                <b-row>
                  <b-col
                    v-if="dataInfo.validations.categoryVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.category"
                      :value="dataInfo.category"
                      :label="'Category'"
                      :options="category"
                      image="/images/members/category.svg"
                      :make-required="dataInfo.validations.categoryRequired"
                      @update="getDistricts"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.casteVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.caste"
                      :value="dataInfo.caste"
                      :label="'Caste'"
                      image="/images/members/caste.svg"
                      :type="'text'"
                      :make-required="dataInfo.validations.casteRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.bankVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.bankAccountNo"
                      :value="dataInfo.bankAccountNo"
                      :label="'Bank Account No.'"
                      image="/images/members/accountNo.svg"
                      :type="'number'"
                      :make-required="dataInfo.validations.bankRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.ifscVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.ifsc"
                      :value="dataInfo.ifsc"
                      :mask="'AAAA0XXXXXX'"
                      :label="'IFSC'"
                      image="/images/members/ifsc.svg"
                      :type="'text'"
                      :make-required="dataInfo.validations.ifscRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.incomeCatVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.incomeId"
                      :value="dataInfo.incomeId"
                      :label="'Income Category'"
                      :options="incomeTypes"
                      option-value="id"
                      option-label="name"
                      image="/images/members/incomeCat.svg"
                      :make-required="dataInfo.validations.incomeCatRequired"
                      @update="getDistricts"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.underageVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.children"
                      :value="dataInfo.children"
                      :label="'Underage Children'"
                      :mask="'##'"
                      image="/images/members/children.svg"
                      :type="'text'"
                      :make-required="
                        dataInfo.validations.underageRequired
                      "
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.earnAdultsVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.earningAdults"
                      :value="dataInfo.earningAdults"
                      :label="'Earning Adults'"
                      :mask="'##'"
                      image="/images/members/employed.svg"
                      :type="'text'"
                      :make-required="
                        dataInfo.validations.earnAdultsRequired
                      "
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.nonEarningVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.nonEarningAdults"
                      :value="dataInfo.nonEarningAdults"
                      :label="'Non-Earning Adults'"
                      :mask="'##'"
                      image="/images/members/unemployed.svg"
                      :type="'text'"
                      :make-required="
                        dataInfo.validations.nonEarningRequired
                      "
                    />
                  </b-col>
                </b-row>
              </app-collapse-item>

              <app-collapse-item
                v-if="!isNonMember && unionSelected && unionSelected.showEconomicFields"
                :title="'Card Numbers'"
                class="bg-cu-orange bo-orange"
              >
                <b-row>
                  <b-col
                    v-if="dataInfo.validations.rationTypeVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.rationCardType"
                      :value="dataInfo.rationCardType"
                      :label="'Ration Card Type'"
                      :options="rationCardTypes"
                      image="/images/members/rationType.svg"
                      :make-required="dataInfo.validations.rationTypeRequired"
                      @update="getDistricts"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.rationNoVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.rationCardNo"
                      :value="dataInfo.rationCardNo"
                      :label="'Ration Card No.'"
                      image="/images/members/rationNo.svg"
                      :type="'text'"
                      :make-required="
                        dataInfo.validations.rationNoRequired
                      "
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.pensionNoVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.pensionPayOrder"
                      :value="dataInfo.pensionPayOrder"
                      :label="'Pension Pay Order'"
                      image="/images/members/pensionNo.svg"
                      :type="'text'"
                      :make-required="dataInfo.validations.pensionRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.jobCardVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.jobCardNo"
                      :value="dataInfo.jobCardNo"
                      :label="'Job Card No.'"
                      image="/images/members/jobCard.svg"
                      :type="'text'"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.janAadharVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.janAadhaarNo"
                      :value="dataInfo.janAadhaarNo"
                      :label="'Jan Aadhaar No.'"
                      image="/images/members/janAadhaar.svg"
                      :type="'text'"
                      :make-required="
                        dataInfo.validations.janAadharRequired
                      "
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.shramikNoVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.shramikDiaryNo"
                      :value="dataInfo.shramikDiaryNo"
                      :label="'Shramik Diary No.'"
                      image="/images/members/shramikNo.svg"
                      :type="'text'"
                      :make-required="
                        dataInfo.validations.shramikNoRequired
                      "
                    />
                  </b-col>
                </b-row>
              </app-collapse-item>

              <app-collapse-item
                v-if="!isNonMember && unionSelected && unionSelected.showWorkFields"
                :title="'Work'"
                class="bg-cu-purple bo-purple"
              >
                <b-row>
                  <b-col
                    v-if="dataInfo.validations.typeOfWorkVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.workType"
                      :value="dataInfo.workType"
                      :label="'Type of Work'"
                      option-value="value"
                      option-label="text"
                      :options="workTypes"
                      image="/images/members/workType.svg"
                      :make-required="dataInfo.validations.typeOfWorkRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.suchWorkSinceVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.yearsInWork"
                      :value="dataInfo.yearsInWork"
                      :label="'Such Work Since'"
                      image="/images/members/workYears.svg"
                      :type="'text'"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.workLocalityVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.workLocality"
                      :value="dataInfo.workLocality"
                      :label="'Work Locality'"
                      image="/images/members/workLocality.svg"
                      :type="'text'"
                      :make-required="
                        dataInfo.validations.workLocalityRequired
                      "
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.employerVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.employerName"
                      :value="dataInfo.employerName"
                      :label="'Name of Employer'"
                      image="/images/members/employerName.svg"
                      :type="'text'"
                      :make-required="
                        dataInfo.validations.employerRequired
                      "
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.meanOfTransportVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.transportMeans"
                      :value="dataInfo.transportMeans"
                      :label="'Means of Transport'"
                      option-value="value"
                      option-label="text"
                      :options="transportMeans"
                      image="/images/members/transport.svg"
                      :make-required="dataInfo.validations.meanOfTransportRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.migrantFromVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.migrantFrom"
                      :value="dataInfo.migrantFrom"
                      :label="'Migrant from (state)'"
                      option-value="id"
                      option-label="name"
                      :options="state"
                      image="/images/members/migrant.svg"
                      :make-required="dataInfo.validations.migrantFromRequired"
                    />
                  </b-col>
                </b-row>
              </app-collapse-item>

              <app-collapse-item
                v-if="!isNonMember && unionSelected && unionSelected.showHealthEduFields"
                :title="'Health & Education'"
                class="bg-cu-green bo-green"
              >
                <b-row>
                  <b-col
                    v-if="dataInfo.validations.educationVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.education"
                      :value="dataInfo.education"
                      :label="'Education'"
                      option-value="value"
                      option-label="text"
                      :options="educations"
                      image="/images/members/education.svg"
                      :make-required="dataInfo.validations.educationRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.skillsVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.skills"
                      :value="dataInfo.skills"
                      :label="'Skills'"
                      image="/images/members/skills.svg"
                      :type="'text'"
                      :make-required="dataInfo.validations.skillsRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.handicapVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.handicap"
                      :value="dataInfo.handicap"
                      :label="'Handicap'"
                      option-value="value"
                      option-label="text"
                      :options="handicapOptions"
                      image="/images/members/handicap.svg"
                    />
                  </b-col>
                </b-row>
              </app-collapse-item>

              <app-collapse-item
                v-if="unionSelected && unionSelected.showMembership"
                :title="'Membership'"
                class="bg-cu-blue bo-blue"
              >
                <b-row>
                  <b-col
                    v-if="dataInfo.validations.memberVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.currentMemberShip.id"
                      :value="dataInfo.currentMemberShip.id"
                      :label="'Member Id'"
                      image="/images/members/memberId.svg"
                      :type="'text'"
                      :make-required="dataInfo.validations.memberRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.membershipStatusVisible"
                    md="4"
                  >
                    <select-group
                      v-model="dataInfo.currentMemberShip.status"
                      :value="dataInfo.currentMemberShip.status"
                      :label="'Membership Status'"
                      :options="status"
                      image="/images/members/status.svg"
                      :make-required="dataInfo.validations.membershipStatusRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.reasonIfSuspendedVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.currentMemberShip.reasonForCancel"
                      :value="dataInfo.currentMemberShip.reasonForCancel"
                      :label="'Reason if Suspended'"
                      image="/images/members/cancelReason.svg"
                      :type="'text'"
                      :make-required="
                        dataInfo.validations.reasonIfSuspendedRequired
                      "
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.joiningDateVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.currentMemberShip.joiningDate"
                      :value="dataInfo.currentMemberShip.joiningDate"
                      :label="'First Joining Date'"
                      image="/images/members/memberSince.svg"
                      :type="'date'"
                      :make-required="
                        dataInfo.validations.joiningDateRequired"
                    />
                  </b-col>
                  <b-col
                    v-if="dataInfo.validations.paidTillVisible"
                    md="4"
                  >
                    <input-group
                      v-model="dataInfo.currentMemberShip.feesPaidTill"
                      :value="dataInfo.currentMemberShip.feesPaidTill"
                      :label="'Fees Paid Till Date'"
                      image="/images/members/memberSince.svg"
                      :type="'date'"
                      :make-required="
                        dataInfo.validations.paidTillRequired"
                    />
                  </b-col>
                  <b-col md="12">
                    <p><b>Roles</b></p>
                    <b-row>
                      <b-col md="4">
                        <input
                          v-model="dataInfo.roles"
                          type="checkbox"
                          value="Member Adder"
                        > Member Adder
                      </b-col>
                      <b-col md="4">
                        <input
                          v-model="dataInfo.roles"
                          type="checkbox"
                          value="Money Collector"
                        > Money Collector
                      </b-col>
                      <b-col md="4">
                        <input
                          v-model="dataInfo.roles"
                          type="checkbox"
                          value="Accountant"
                        > Accountant
                      </b-col>
                      <b-col md="4">
                        <input
                          v-model="dataInfo.roles"
                          type="checkbox"
                          value="Treasurer"
                        > Treasurer
                      </b-col>
                      <b-col md="4">
                        <input
                          v-model="dataInfo.roles"
                          type="checkbox"
                          value="Team Lead"
                        > Team Lead
                      </b-col>
                      <b-col md="4">
                        <input
                          v-model="dataInfo.roles"
                          type="checkbox"
                          value="Group Admin"
                        > Group Admin
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </app-collapse-item>

            </app-collapse>

          </b-col>
          <b-col
            md="12"
            class="text-center mt-2"
          >
            <button
              class="btn btn-primary"
              @click="saveUsers"
            >
              Save
            </button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BForm,
} from 'bootstrap-vue'
import InputGroup from '@/@core/layouts/shared/InputGroup.vue'
import SelectGroup from '@/@core/layouts/shared/SelectGroup.vue'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import {
  hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification,
} from '@/@core/comp-functions/ui/app'
import {
  appGetBlocks, appGetDistricts, appGetPanchayat, appGetStates, appGetSubDistrict, appGetUserById, appTransactionTypes, appUpdateUsers, appGetGroupSetting,
} from '@/@core/services/groups'
import { generateGuid, getUniqueNo, goToPath } from '@/auth/utils'
import roles from '@/@core/services/roles'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    InputGroup,
    SelectGroup,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      isSubmitted: false,
      dataInfo: {
        address: {},
        currentMemberShip: {},
        validations: {},
      },
      state: [],
      district: [],
      taluk: [],
      block: [],
      panchayat: [],
      transactionTypes: [],
      category: this.$manMudra.constants.category,
      workTypes: this.$manMudra.constants.workTypes,
      educations: this.$manMudra.constants.educations,
      status: this.$manMudra.constants.status,
      handicapOptions: this.$manMudra.constants.handicapOptions,
      rationCardTypes: this.$manMudra.constants.rationCardTypes,
      wantsToLearnNewSkills: this.$manMudra.constants.wantsToLearnNewSkills,
      transportMeans: this.$manMudra.constants.transportMeans,
      userId: this.$route.params.memberId,
      groupValidationLoaded: false,
    }
  },
  computed: {
    unionSelected() {
      return this.$store.getters['groups/currentUnion']
    },
    incomeTypes() {
      return this.transactionTypes.filter(
        x => x.code === this.$constants.Codes.INCOME,
      )
    },
    isNonMember() {
      if (this.dataInfo && !this.dataInfo.isMember) {
        return true
      }
      return this.$route.meta.actionType === 'add-non-member'
    },
  },
  watch: {
    unionSelected: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.resetUser()
          this.getGroupValidation()
        }, 150)
      },
    },
  },
  mounted() {
    this.getStates()
    this.resetUser()
    if (this.userId) {
      this.getUserById()
    }
    this.getGroupValidation()
  },
  methods: {
    saveUsers() {
      this.isSubmitted = true
      this.$nextTick(() => {
        if (window.ManMudra.validations.get()) {
          window.ManMudra.validations.focus()
          return
        }
        this.isSubmitted = false
        showLoader()
        const user = window.ManMudra.actions.cloneDeep(this.dataInfo)
        user.unionId = this.unionSelected.id
        if (user.countryCode) {
          user.mobileNo = user.mobileNo.replace(/\D+/g, '')
          user.mobileNo = `${user.countryCode}${user.mobileNo}`
        }
        if (user.mobileNo) {
          if (!user.mobileNo.startsWith('+91')) {
            user.mobileNo = user.mobileNo.replace(/\D+/g, '')
            user.mobileNo = `+91${user.mobileNo}`
          }
        }
        if (Array.isArray(this.dataInfo.unionMemberships)) {
          const membershipExist = this.dataInfo.unionMemberships.find(
            // eslint-disable-next-line eqeqeq
            x => x.unionId == user.unionId,
          )
          if (membershipExist) {
            Object.keys(membershipExist).forEach(x => {
              user.unionMemberships.find(
                // eslint-disable-next-line eqeqeq
                y => y.unionId == user.unionId,
              )[x] = user.currentMemberShip[x]
            })
          }
        }
        if (!Array.isArray(user.roles)) {
          user.roles = []
        }
        if (user.roles.length === 0) {
          user.roles.push(roles.ordinaryMember)
        }
        appUpdateUsers(user)
          .then(({ data }) => {
            hideLoader()
            if (data.succeeded) {
              goToPath(this, 'group-members-list')
              showSuccessNotification(this, data.message)
            } else {
              showDangerNotification(this, data.message)
            }
          })
          .catch(error => {
            hideLoader()
            showErrorNotification(this, error)
          })
      })
    },
    getUserById() {
      showLoader()
      appGetUserById({
        id: this.userId,
      }).then(({ data }) => {
        if (data.succeeded) {
          const user = data.data
          if (user.dateOfBirth) {
            user.dateOfBirth = this.$helpers.formatDate(user.dateOfBirth)
          }
          if (user.mobileNo) {
            const mobileIndex = user.mobileNo.length - 10
            user.countryCode = user.mobileNo.substring(0, mobileIndex)
            user.mobileNo = user.mobileNo.substring(mobileIndex)
          }
          user.fatherOrHusbandOf = ''
          if (user.fatherOrHusbandName) {
            const splitData = user.fatherOrHusbandName.split(':')
            user.fatherOrHusbandOf = splitData[0]
              ? splitData[0]
              : this.guardian.sonDaugter
            if (
              user.fatherOrHusbandOf === 'H'
              || user.fatherOrHusbandOf === 'F'
            ) {
              user.fatherOrHusbandOf = this.guardian.sonDaugter
            }
            // eslint-disable-next-line prefer-destructuring
            user.fatherOrHusbandName = splitData[1]
          }
          const membershipExist = user.unionMemberships.find(
            x => x.unionId === user.unionId,
          )
          if (membershipExist) {
            user.currentMemberShip = this.$manMudra.actions.cloneDeep(membershipExist)
            if (user.currentMemberShip.joiningDate) {
              user.currentMemberShip.joiningDate = this.$helpers.formatDate(
                user.currentMemberShip.joiningDate,
              )
            }
            if (user.currentMemberShip.feesPaidTill) {
              user.currentMemberShip.feesPaidTill = this.$helpers.formatDate(
                user.currentMemberShip.feesPaidTill,
              )
            }
          } else {
            user.currentMemberShip = {
              id: getUniqueNo(),
              unionId: this.unionSelected.id,
              status: this.$constants.Status[0],
              reasonForCancel: '',
              joiningDate: new Date(),
              feesPaidTill: null,
            }
          }
          this.dataInfo = user
          if (!this.dataInfo.address) {
            this.setInitialAddressFields()
          }
          this.setAddressFields()
        } else {
          showDangerNotification(this, data.message)
        }
        hideLoader()
      })
    },
    resetUser() {
      this.dataInfo = {
        firstName: '',
        address: {
          isUrban: true,
        },
        currentMemberShip: {},
        surname: '',
        photo: '',
        fatherOrHusbandName: '',
        isMember: this.$route.meta.actionType === 'add-member',
        fAadhaarNo: '',
        aadhaarNo: '',
        fMobileNo: '',
        countryCode: '',
        mobileNo: '',
        organization: '',
        landlinePhone: '',
        dateOfBirth: null,
        isAge: false,
        gender: '',
        maritalStatus: '',
        workType: null,
        yearsInWork: null,
        workLocality: '',
        employerName: '',
        transportMeans: '',
        migrantFrom: null,
        category: null,
        caste: '',
        rationCardType: null,
        rationCardNo: '',
        pensionPayOrder: '',
        jobCardNo: '',
        janAadhaarNo: '',
        shramikDiaryNo: '',
        papersVerified: false,
        bankAccountNo: '',
        ifsc: '',
        education: null,
        skills: '',
        wantsToLearnNewSkills: null,
        leadershipRoles: '',
        handicap: null,
        earningAdults: null,
        nonEarningAdults: null,
        children: null,
        unionId: '',
        unionMemberships: [],
        roles: [],
        stateId: null,
        districtId: null,
        subDistrictId: null,
        blockId: null,
        panchayatId: null,
        cityOrVillage: '',
        pinCode: null,
        houseNo: '',
        landMark: '',
        locality: '',
        postOffice: '',
        email: '',
        incomeId: null,
        suspendedReason: '',
        dateOfJoin: null,
        id: generateGuid(),
      }
      this.dataInfo.address = {}
      this.dataInfo.currentMemberShip = {}
      this.setInitialAddressFields()
    },
    setInitialAddressFields() {
      if (!this.dataInfo.currentMemberShip) {
        this.dataInfo.currentMemberShip = {}
      }
      this.dataInfo.address = {
        id: 0,
        stateId: null,
        districtId: null,
        subDistrictId: null,
        blockId: null,
        panchayatId: null,
        cityOrVillage: null,
        pinCode: null,
        houseNo: null,
        landMark: null,
        locality: null,
        postOffice: null,
        email: null,
        isUrban: false,
      }
    },
    setAddressFields() {
      if (this.dataInfo.address.stateId) {
        this.getDistricts()
      }
      if (this.dataInfo.address.districtId) {
        this.getSubDistricts()
        this.getBlock()
      }
      if (this.dataInfo.address.blockId) {
        this.getPanchayat()
      }
    },
    getStates() {
      appGetStates().then(({ data }) => {
        if (data.succeeded) {
          this.state = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getDistricts(reset) {
      if (reset) {
        this.dataInfo.address.districtId = null
        this.district = []
        this.taluk = []
        this.panchayat = []
        this.block = []
        this.dataInfo.address.subDistrictId = null
        this.dataInfo.address.blockId = null
        this.dataInfo.address.panchayatId = null
      }
      appGetDistricts({
        stateId: this.dataInfo.address.stateId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.district = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getSubDistricts(reset) {
      if (reset) {
        this.taluk = []
        this.block = []
        this.panchayat = []
        this.dataInfo.address.subDistrictId = null
        this.dataInfo.address.blockId = null
        this.dataInfo.address.panchayatId = null
      }
      appGetSubDistrict({
        districtId: this.dataInfo.address.districtId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.taluk = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getBlock(reset) {
      if (reset) {
        this.panchayat = []
        this.block = []
        this.dataInfo.address.panchayatId = null
        this.dataInfo.address.blockId = null
      }
      appGetBlocks({
        districtId: this.dataInfo.address.districtId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.block = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getPanchayat(reset) {
      if (reset) {
        this.panchayat = []
        this.dataInfo.address.panchayatId = null
      }
      appGetPanchayat({
        blockId: this.dataInfo.address.blockId,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.panchayat = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getTransactionTypes() {
      appTransactionTypes().then(({ data }) => {
        if (data.succeeded) {
          this.transactionTypes = data.data
        } else {
          showDangerNotification(this, data.message)
        }
      })
    },
    getGroupValidation() {
      showLoader()
      appGetGroupSetting({
        id: this.unionSelected.id,
      }).then(({ data }) => {
        if (data.succeeded) {
          this.dataInfo = {
            ...this.dataInfo,
            validations: data.data,
          }
          hideLoader()
        } else {
          showDangerNotification(data.message)
        }
        this.groupValidationLoaded = true
      })
    },
  },
}
</script>

<style lang="scss">
.bg-cu-yellow > .card-header{
  background: #f6f5d8 !important;
}
.bg-cu-amber .card-header{
  background: #fff2cc !important;
}

.bg-cu-orange .card-header{
  background: #fbe5d6 !important;
}

.bg-cu-purple .card-header{
  background: #ede2f6 !important;
}

.bg-cu-green .card-header{
  background: #e2f0d9 !important;
}

.bg-cu-blue .card-header{
  background: #deebf7 !important;
}
.bo-yellow {
  border-left: 3px solid #c7c32f !important;
}
.bo-amber {
  border-left: 3px solid #c08e00 !important;
}

.bo-orange {
  border-left: 3px solid #e86b16 !important;
}

.bo-purple {
  border-left: 3px solid #ad7cd6 !important;
}

.bo-green {
  border-left: 3px solid #89c365 !important;
}

.bo-blue {
  border-left: 3px solid #4d93d3 !important;
}

</style>
